/* eslint-disable import/prefer-default-export */
import { Response } from '../../../models/response.model';
import { initializeApollo } from '../../../graphql/apollo';
import {
  Book,
  GetRecentViewsDocument,
  GetRecentViewsQuery,
  GetRecentViewsQueryVariables,
  RelatedProductsDocument,
  RelatedProductsQuery,
  RelatedProductsQueryVariables,
} from '../../../graphqlTypes';

export const getRecentViews = async (
  variables: GetRecentViewsQueryVariables,
): Promise<Response<Book[]>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { getRecentViews: getRecentViewsData },
    } = await query<GetRecentViewsQuery>({
      query: GetRecentViewsDocument,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!getRecentViewsData,
      data: getRecentViewsData as Book[],
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};

export const getRelatedProducts = async (
  variables: RelatedProductsQueryVariables,
): Promise<Response<Book[]>> => {
  try {
    const { query } = initializeApollo();

    const {
      data: { relatedProducts },
    } = await query<RelatedProductsQuery>({
      query: RelatedProductsDocument,
      variables,
      fetchPolicy: 'cache-first',
    });

    return {
      ok: !!relatedProducts,
      data: relatedProducts as Book[],
    };
  } catch (error: any) {
    return {
      ok: false,
      messageError: error?.message,
    };
  }
};
