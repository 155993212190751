import React, { useEffect, useState } from 'react';
import speakingUrl from 'speakingurl';
import { useRouter } from 'next/router';
import { Area } from '../../../graphqlTypes';
import Button from '../forms/Button';
import Link from '../link/Link';
import { correlation } from '../../../utils/utils';
import { getAreas } from './comingSoonBanner.service';

interface ComingSoonBannerProps {
  category?: string;
  className?: string;
}

const ComingSoonBanner = ({ category, className: classes }: ComingSoonBannerProps): JSX.Element => {
  const [isMaterial, setIsMaterial] = useState<boolean>();
  const [areasData, setAreasData] = useState<Area>(null);
  const router = useRouter();

  const getAreasQuery = async (): Promise<void> => {
    const { data, ok } = await getAreas();
    if (!ok) return;

    setAreasData(data);
  };

  useEffect(() => {
    getAreasQuery();
  }, [router.asPath]);

  useEffect(() => {
    setIsMaterial(category === 'material');
  }, [category]);

  const confirmFn = (): void => {
    const slug = speakingUrl(areasData?.nameEs?.replace(/, /g, '.'), {
      custom: ['.'],
    });

    router.push(`/${correlation[category]}/proximamente/${areasData?.id}/${slug}`);
  };

  return (
    <div className={`comingSoonBanner animate ${classes || ''}${isMaterial ? ' image' : ''}`}>
      {isMaterial ? (
        <Link href="/resultados/brands/material/littmann">
          <img src="/img/banner-littmann.jpg" alt="Littman" loading="lazy" />
        </Link>
      ) : (
        <React.Fragment>
          <p>{`Vea las próximas publicaciones en ${areasData?.nameEs}`}</p>
          <Button
            onClick={confirmFn}
            className="rounded bordered"
            iconRight
            iconClass="arrow-right"
          >
            Ir al listado
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};

export default ComingSoonBanner;
