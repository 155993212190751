import create from 'zustand';
import { DataProducts, ProductsState } from './products.state';

interface ProductStore {
  dataProduct?: ProductsState;
  setStateBySection: (sectionName: string, dataProduct: DataProducts) => void;
  setInitialStateBySection?: (
    sectionName: string,
    dataProduct: DataProducts,
    keepResult?: boolean,
  ) => void;
}

const useStoreProducts = create<ProductStore>((set, get) => ({
  dataProduct: {},
  setStateBySection(sectionName: string, dataProduct: DataProducts): void {
    if (!sectionName) return;
    const newPayLoad: ProductsState = {
      [sectionName]: dataProduct,
    };

    set((state) => ({
      ...state,
      dataProduct: {
        ...state.dataProduct,
        ...newPayLoad,
      },
    }));
  },
  setInitialStateBySection(
    sectionName: string,
    dataProduct: DataProducts,
    keepResult?: boolean,
  ): void {
    if (!sectionName) return;

    const currentSection = get().dataProduct[sectionName];
    if (currentSection && keepResult) return;

    const newPayLoad: ProductsState = {
      [sectionName]: dataProduct,
    };

    set((state) => ({
      ...state,
      dataProduct: {
        ...state.dataProduct,
        ...newPayLoad,
      },
    }));
  },
}));

export default useStoreProducts;
