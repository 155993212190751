import React, { useEffect, useState } from 'react';
import Sliders, { Settings } from 'react-slick';
import Icon from '../Icon';
import ComingSoonBanner from '../comingSoonBanner/ComingSoonBanner';
import useWindowSize from '../../../hooks/useWindowSize';

interface SliderHolderProps {
  noAutoplay?: boolean;
  arrows?: boolean;
  children: JSX.Element | JSX.Element[];
  category?: string;
  closeSlider?: () => void;
  className?: string;
  slidesToShow?: number;
  slidesToScroll?: number;
  home?: boolean;
  dots?: boolean;
  limited?: boolean;
  product?: boolean;
  autoplaySpeed?: number;
  variableWidth?;
  customPaging?;
  removable?: boolean;
  areaOfInterest?;
  changeFixedFilter?: (data) => void;
}

interface SliderHolderState {
  comingSoon: boolean;
  className: string;
  classNameComing: string;
}

const SliderHolder = (props: SliderHolderProps): JSX.Element => {
  const {
    className,
    dots,
    children,
    noAutoplay,
    product,
    autoplaySpeed,
    slidesToShow,
    slidesToScroll,
    variableWidth,
    customPaging,
    home,
    category,
    removable,
    areaOfInterest,
    closeSlider,
    changeFixedFilter,
  } = props;

  const { height } = useWindowSize();

  const [state, setState] = useState<SliderHolderState>();

  const onSwipe = (e): void => {
    const childrenProp = children[e];
    if ((childrenProp as React.ReactElement)?.props?.children?.props?.comingSoon) {
      setState((prevState) => ({
        ...prevState,
        comingSoon: true,
        className: 'comingSoon',
        classNameComming: 'fadeInRight',
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      comingSoon: false,
      className: '',
      classNameComming: 'fadeOutRight',
    }));
  };

  const settings: Settings = {
    dots: dots || false,
    // infinite: !(limited || children.length === 0),
    // adaptiveHeight,
    autoplay: !noAutoplay && !product,
    customPaging,
    speed: 1500,
    autoplaySpeed: autoplaySpeed || 6000,
    slidesToShow,
    slidesToScroll,
    // arrows: children &&.length === 1 ? false : arrows,
    variableWidth,
    lazyLoad: 'ondemand',
    pauseOnFocus: false,
    pauseOnHover: true,
    className: `${className} ${state?.className}`,
    pauseOnDotsHover: false,
    afterChange: onSwipe,
  };

  const changeFixedFilters = (): void => {
    if (changeFixedFilter) {
      changeFixedFilter(height);
    }
  };

  useEffect(() => {
    changeFixedFilters();
  }, []);

  const getClassComing = (): string => {
    if (category === 'material') {
      return '';
    }

    if ((children[0] as React.ReactElement)?.props.children && !state.classNameComing) {
      return 'fadeOutRight first';
    }

    return state.classNameComing;
  };

  const closeSliderFn = (): void => {
    if (closeSlider) {
      closeSlider();
    }
  };

  const getClassName = (): string => {
    if (!home && category !== 'material') {
      return ' regular';
    }

    return home ? ' fullscreen' : '';
  };

  return (
    <div
      className={`sliderHolder ${className || ''} ${state?.className} ${getClassName()}${
        dots ? ' dots' : ''
      }${product ? ' product' : ''}`}
    >
      <div className={`productBox${areaOfInterest ? '' : ' notBanner'}`}>
        {removable ? <Icon onClick={closeSliderFn} className="close" /> : null}
        <Sliders {...settings}>{children}</Sliders>
      </div>
      {areaOfInterest ? (
        <div className="imgBox">
          <ComingSoonBanner category={category} className={getClassComing()} />
        </div>
      ) : null}
    </div>
  );
};

export default SliderHolder;
