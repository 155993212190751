import { getDataFromStorage, NameStorage } from '../../../../utils/services/localStorage.service';

const getNameRelated = (category: string): string => {
  const existAreaOfInterest = !!getDataFromStorage<number>(NameStorage.areaOfInterest);

  if (['book', 'ebook'].includes(category) && existAreaOfInterest) {
    return `${category}WithAreaRelated`;
  }

  return `${category}Related`;
};

export default getNameRelated;
