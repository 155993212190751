import React, { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import LoadingOval from '../LoadingOval';
import ProductPreview from '../productPreview/ProductPreview';
import useWindowSize from '../../../hooks/useWindowSize';
import { Book } from '../../../graphqlTypes';
import { SCREEN_SIZES } from '../../../utils/constants';
import SliderHolder from '../slider/SliderHolder';
import { getRecentViews, getRelatedProducts } from './related.service';
import useLoadingListProducts from '../../../hooks/useLoadingListProducts';
import getNameRelated from './utils/related.utils';
import useStoreProducts from '../../../reducer/productsList/storeProducts';
import { DataProducts } from '../../../reducer/productsList/products.state';

interface RelatedProps {
  eanBook?: string;
  idUser?: string;
  bestSellers?: boolean;
  category?: string;
  home?: boolean;
  numberOfBooks?: number;
  title?: string;
  recentViews?: boolean;
  setRecentlyViewed?: (view: number) => void;
  listRelatedProducts?: Book[];
  withBorder?: boolean;
  isPageList?: boolean;
}

interface RelatedState {
  ean: string;
  idUser: string;
  category: string;
  numBooks: number;
  relatedProducts: Book[];
}

const Related = ({
  eanBook,
  idUser,
  category,
  home = false,
  numberOfBooks,
  title,
  recentViews,
  setRecentlyViewed,
  listRelatedProducts,
  withBorder,
}: RelatedProps): JSX.Element => {
  const { documentWidth } = useWindowSize();
  const [state, setState] = useState<RelatedState>({
    ean: eanBook,
    idUser,
    category,
    numBooks: numberOfBooks,
    relatedProducts: [],
  });
  const sectionName = getNameRelated(category);
  const [productsState, setProductsState] = useStoreProducts(
    (stateStore) => [stateStore.dataProduct, stateStore.setInitialStateBySection],
    shallow,
  );
  const { books: relatedProducts = listRelatedProducts } =
    (productsState?.[sectionName] as DataProducts) || {};

  const [loadingRelated, setLoadingRelated] = useState(false);

  const { currentProductClicked, isClickedProduct } = useLoadingListProducts();

  const doQuery = async (): Promise<void> => {
    let relatedProductsFinal: Book[] = [];

    if (recentViews && idUser) {
      setLoadingRelated(true);
      const { data } = await getRecentViews({
        idUser: state.idUser,
      });

      relatedProductsFinal = data;
      setRecentlyViewed(data?.length);
    }

    if (state.ean) {
      setLoadingRelated(true);
      const { data } = await getRelatedProducts({
        eanProduct: state?.ean,
      });
      relatedProductsFinal = data as Book[];
    }

    setState((prevState) => ({
      ...prevState,
      relatedProducts: relatedProductsFinal,
    }));
    setLoadingRelated(false);
  };

  useEffect(() => {
    doQuery();
  }, []);

  useEffect(() => {
    setProductsState(sectionName, {
      books: listRelatedProducts,
    });
  }, [listRelatedProducts]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ean: eanBook,
      idUser,
      category,
      numBooks: numberOfBooks,
    }));
    if (idUser) doQuery();
  }, [eanBook, idUser, category, numberOfBooks]);

  const getNumberItemSlider = (): number => {
    let number = state?.numBooks || 4;
    if (documentWidth >= SCREEN_SIZES.MIN_DESK && documentWidth < SCREEN_SIZES.MAX_DESK) {
      number = state?.numBooks || 4;
    }

    if (documentWidth >= SCREEN_SIZES.MIN_TABLET && documentWidth < SCREEN_SIZES.MAX_TABLET) {
      number = 3;
    }

    if (documentWidth >= SCREEN_SIZES.MIN_MOBILE && documentWidth < SCREEN_SIZES.MAX_MOBILE) {
      number = 2;
    }

    if (relatedProducts?.length < number) return relatedProducts?.length;

    return number;
  };

  if (
    !loadingRelated &&
    ((!home && (relatedProducts || state.relatedProducts)?.length < 4) ||
      !(relatedProducts || state.relatedProducts)?.length)
  ) {
    return null;
  }

  return (
    <div className={`relatedWrapper ${home ? ' home plusSold' : ''}`}>
      {state?.numBooks ? null : <h3>{title || 'Productos relacionados'}</h3>}

      {loadingRelated ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingOval ariaLabel="Loading-Related-Query" height={40} width={40} strokeWidth={8} />
        </div>
      ) : null}

      <SliderHolder
        noAutoplay
        arrows
        className="relatedSlider"
        slidesToShow={getNumberItemSlider()}
        slidesToScroll={getNumberItemSlider()}
      >
        {(relatedProducts || state.relatedProducts)?.map((relatedProduct, index) => {
          if (index > 19) return null;

          return (
            <div key={`d_s_i${relatedProduct._id}`} className="slide slideRelated">
              <div className="row productList related">
                <ProductPreview
                  results
                  key={`l_p_${relatedProduct.id}`}
                  category={category}
                  element={relatedProduct}
                  withBorder={withBorder}
                  onClick={currentProductClicked}
                  isLoading={isClickedProduct(relatedProduct)}
                />
              </div>
            </div>
          );
        })}
      </SliderHolder>
    </div>
  );
};

export default React.memo(Related);
